<template>
    <div class="roadFront">
        <el-container class="ova-y">
            <el-main class="p0">
                <el-row>
                    <el-col :span="24">
                        <div class="box" ref="init">
                            <div class="box-content">
                                <el-form :inline="true" class="form-inline formBox">
                                    <el-form-item label="行政区划">
                                        <el-cascader style="width: 160px;margin-right: 10px" size="medium" v-model="region" :options="regions" :props="{ expandTrigger: 'hover' }" clearable @change="handleChange"></el-cascader>
                                    </el-form-item>
                                    <el-form-item label="项目名称">
                                        <el-input style="width: 160px" size="medium" v-model="xmmc" placeholder="请输入" clearable></el-input>
                                    </el-form-item>
                                    <el-form-item label="建设类别">
                                        <el-select v-model="gllx" placeholder="请选择" style="width: 170px">
                                            <el-option label="" value="">全部</el-option>
                                            <el-option label="高速公路" value="GaoSu">高速公路</el-option>
                                            <el-option label="普通国道" value="GuoDao">普通国道</el-option>
                                            <el-option label="普通省道" value="ShengDao">普通省道</el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="项目性质">
                                        <el-cascader size="medium" style="width: 170px" v-model="checkedXmxz" :options="xmxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="建设主体">
                                        <el-input style="width: 160px" size="medium" v-model="frdw" placeholder="请输入" clearable></el-input>
                                    </el-form-item>
                                    <el-form-item label="风险项目">
                                        <el-cascader size="medium" style="width: 170px" v-model="checkedFxxm" :options="fxxmList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
<!--                                    <el-form-item label="建设性质">-->
<!--                                        <el-cascader size="medium" style="width: 160px" v-model="checkedJsgm" :options="jsgmList" placeholder="请选择"  :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>-->
<!--                                        </el-cascader>-->
<!--                                    </el-form-item>-->
                                    <el-form-item>
                                        <el-button type="primary"  style="margin-top:19px;margin-left: 10px"  size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
                                        <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">删除</el-button>
                                    </el-form-item>

                                </el-form>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="plr12">
                    <el-col :span="24">
                        <div class="box">
                            <div class="box-title">
<!--                                <span class="fright">-->
<!--                                    <el-button @click="exportData" type="primary" size="mini" round icon="el-icon-upload2">导出</el-button>-->
<!--                                    <el-button @click="addRoad" type="primary" size="mini" round icon="el-icon-plus">新增</el-button>-->
<!--                                </span>-->
                                <h3 class="tit">公路列表</h3>
                            </div>
                            <div class="box-content">

                                <el-table :highlight-current-row="true" :row-class-name="tableRowClassName" @sort-change="changeSort" @row-dblclick="openDetailsEdit" ref="table" :data="tableData" size="small " :height="tableHeight" border  style="width: 100%">
                                    <el-table-column fixed prop="index"  label="序号"  align="center" type="index" :width="50" :index="1+30*(currentPage-1)"></el-table-column>
                                    <el-table-column fixed prop="" align="center" :width="300" label="操作">
                                        <template slot-scope="scope">
                                            <span class="newicon iconsyes" :class="{iconsyes1:!scope.row.XMXH}" @click="showMaps1(scope.row)"><i class="el-icon-location icons"></i>路况评定</span>
                                            <span class="newicon iconsyes" :class="{iconsyes1:!scope.row.XMXH}" @click="showMaps(scope.row)"><i class="el-icon-location icons"></i>占地</span>
                                            <span class="newicon iconsyes" @click="openDetailsEdit(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>计划下达</span>
                                            <span class="newicon iconsyes" @click="openTable(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>历年</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="XMMC" label="项目名称" align="center" show-overflow-tooltip :width="320">
                                        <template slot-scope="scope">
                                            <div style="display: flex;align-items: center">
                                                <div v-if="scope.row.ZDFX == '是' || scope.row.ZJFX == '是'"><img style="width: 20px;height: 20px;display: block" src="../../assets/icon/fx.png" alt=""></div>
                                                <div>{{scope.row.XMMC}}</div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="JSZT" label="建设主体" align="center" show-overflow-tooltip :width="150" ></el-table-column>
                                    <el-table-column label="建设规模(公里)/(延米)" :width="150"  align="center">
                                        <el-table-column prop="GM_HJ" label="合计" width="100" align="center"></el-table-column>
                                        <el-table-column prop="GM_GSGL" label="高速" width="100" align="center"></el-table-column>
                                        <el-table-column prop="GM_YJGL" label="一级" width="100" align="center"></el-table-column>
                                        <el-table-column prop="GM_EJGL80" label="二级(80km/h)" width="120" align="center"></el-table-column>
                                        <el-table-column prop="GM_EJGL60" label="二级(60km/h)" width="120" align="center"></el-table-column>
                                        <el-table-column prop="GM_SANJGL" label="三级" width="100" align="center"></el-table-column>
                                        <el-table-column prop="GM_SIJGL" label="四级" width="100" align="center"></el-table-column>
                                        <el-table-column prop="GM_DLDQ" label="独立大桥" width="100" align="center"></el-table-column>
                                    </el-table-column>
                                    <el-table-column label="建设年限"  align="center">
                                        <el-table-column prop="KGN" label="开工年"  align="center"></el-table-column>
                                        <el-table-column prop="WGN" label="完工年"  align="center"></el-table-column>
                                    </el-table-column>
                                    <el-table-column prop="ZTZ"  align="center" label="总投资(万元)" :width="150"  show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="ZYTZSJBZ"  align="center" label="中央投资（车购税）/省级补助资金" :width="150"  show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="JZLJXDJH"  align="center" label="截止2021年底累计下达计划" :width="150"  show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="ZJFX"  align="center" label="资金风险" :width="150"  show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="ZDFX"  align="center" label="占地风险" :width="150"  show-overflow-tooltip></el-table-column>
                                </el-table>
                                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
        <div class="editWrap">
            <el-drawer :wrapperClosable="false" :visible.sync="showAddModel" @close="closeDrawer" size="1140px" :title="detail.XMMC"  class="editModal">
                <el-form :hide-required-asterisk="true" :model="detail" :inline-message="true" ref="detailForm"  :inline="true" class="form-inline" v-loading="uploadLoading">
                    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                        <el-tab-pane label="基本信息" name="0">
                            <el-button  size="small"  type="primary" style="margin-bottom: 10px" icon="el-icon-edit" @click="editStatus = !editStatus">编辑</el-button>
                            <table style="text-align:center">
                                <tr>
                                    <td colspan="2">
                                        <div class="label3"> 对应部"十四五"交通运输专项建设规划项目名称</div>
                                    </td>
                                    <td colspan="4">
                                        <div class="dwmcWrap" v-if="editStatus">
                                            <div @click="chooseXm" class="dwmc">{{XMMC}}</div>
                                            <div class="plus"><i @click="chooseXm" class="el-icon-circle-plus"></i></div>
                                        </div>
                                        <div class="cont" style="width:700px " v-else>{{XMMC}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="label2">项目名称</div>
                                    </td>
                                    <td colspan="3">
                                        <div>{{detail.XMMC}}</div>
                                    </td>
                                    <td>
                                        <div class="label2">建设主体</div>
                                    </td>
                                    <td>
                                        <div class="cont">{{detail.JSZT}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="label2">建设规模合计(公里)</div>
                                    </td>
                                    <td style="width: 220px">
<!--                                        <el-form-item v-if="editStatus"  prop="GM_HJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">-->
<!--                                            <el-input placeholder="合计" disabled v-model="detail.GM_HJ"></el-input>-->
<!--                                        </el-form-item>-->
                                        <div  class="cont">{{detail.GM_HJ}}</div>
                                    </td>
                                    <td>
                                        <div class="label2">建设规模高速(公里)</div>
                                    </td>
                                    <td>
                                        <el-form-item v-if="editStatus" prop="GM_GSGL" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                            <el-input style="width: 205px;height: 32px" @input="changeJstotal($event,'gs')" size="medium" v-model="detail.GM_GSGL" placeholder="建设规模高速"></el-input>
                                        </el-form-item>
                                        <div class="cont"  v-else>{{detail.GM_GSGL}}</div>
                                    </td>
                                    <td>
                                        <div class="label2">建设规模一级(公里)</div>
                                    </td>
                                    <td>
                                        <el-form-item v-if="editStatus" prop="GM_YJGL" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                            <el-input style="width: 205px;height: 32px" @input="changeJstotal($event,'gs')" size="medium" v-model="detail.GM_YJGL" placeholder="建设规模一级"></el-input>
                                        </el-form-item>
                                        <div class="cont"  v-else>{{detail.GM_YJGL}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="label2" style="line-height: unset">建设规模二级80km/h(公里)</div>
                                    </td>
                                    <td class="w20">
                                        <el-form-item v-if="editStatus" prop="GM_EJGL80" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                            <el-input @input="changeJstotal($event,'gs')" style="width: 205px;height: 32px" size="medium" v-model="detail.GM_EJGL80" placeholder="建设规模二级"></el-input>
                                        </el-form-item>
                                        <div class="cont"  v-else>{{detail.GM_EJGL80}}</div>
                                    </td>
                                    <td>
                                        <div class="label2" style="line-height: unset">建设规模二级60km/h(公里)</div>
                                    </td>
                                    <td class="w20">
                                        <el-form-item v-if="editStatus" prop="GM_EJGL60" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                            <el-input @input="changeJstotal($event,'gs')" style="width: 205px;height: 32px" size="medium" v-model="detail.GM_EJGL60" placeholder="建设规模二级"></el-input>
                                        </el-form-item>
                                        <div class="cont"  v-else>{{detail.GM_EJGL60}}</div>
                                    </td>
                                    <td>
                                        <div class="label2">建设规模三级(公里)</div>
                                    </td>
                                    <td>
                                        <el-form-item v-if="editStatus" prop="GM_SANJGL" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                            <el-input @input="changeJstotal($event,'gs')" style="width: 205px;height: 32px" size="medium" v-model="detail.GM_SANJGL" placeholder="建设规模三级"></el-input>
                                        </el-form-item>
                                        <div class="cont"  v-else>{{detail.GM_SANJGL}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="label2">建设规模四级(公里)</div>
                                    </td>
                                    <td>
                                        <el-form-item v-if="editStatus" prop="GM_SIJGL" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                            <el-input @input="changeJstotal($event,'gs')" style="width: 205px;height: 32px" size="medium" v-model="detail.GM_SIJGL" placeholder="建设规模四级"></el-input>
                                        </el-form-item>
                                        <div class="cont"  v-else>{{detail.GM_SIJGL}}</div>
                                    </td>
                                    <td>
                                        <div class="label2">独立大桥(公里)</div>
                                    </td>
                                    <td>
                                        <el-form-item v-if="editStatus" prop="GM_DLDQ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                            <el-input @input="changeJstotal($event,'gs')" style="width: 205px;height: 32px" size="medium" v-model="detail.GM_DLDQ" placeholder="独立大桥"></el-input>
                                        </el-form-item>
                                        <div class="cont"  v-else>{{detail.GM_DLDQ}}</div>
                                    </td>
                                    <td>
                                        <div class="label2">占地风险</div>
                                    </td>
                                    <td>
                                        <el-form-item v-if="editStatus" prop="ZDFX">
                                            <el-select v-model="detail.ZDFX" placeholder="占地风险" style="width: 205px;height: 32px">
                                                <el-option label="是" value="是">是</el-option>
                                                <el-option label="否" value="否">否</el-option>
                                            </el-select>
                                        </el-form-item>
                                        <div class="cont"  v-else>{{detail.ZDFX}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="label2">资金风险</div>
                                    </td>
                                    <td>
                                        <el-form-item v-if="editStatus" prop="ZJFX">
                                            <el-select @change="changeZzfx" v-model="detail.ZJFX" placeholder="资金风险" style="width: 205px;height: 32px">
                                                <el-option label="是" value="是">是</el-option>
                                                <el-option label="否" value="否">否</el-option>
                                            </el-select>
                                        </el-form-item>
                                        <div class="cont"  v-else>{{detail.ZJFX}}</div>
                                    </td>
                                    <td>
                                        <div class="label2">开工年</div>
                                    </td>
                                    <td>
                                        <div class="cont">{{detail.KGN}}</div>
                                    </td>
                                    <td>
                                        <div class="label2">完工年</div>
                                    </td>
                                    <td>
                                        <div class="cont">{{detail.WGN}}</div>
                                    </td>
                                </tr>
                                <tr v-if="zzfxyy">
                                    <td>
                                        <div class="label2">资金风险原因</div>
                                    </td>
                                    <td colspan="5" style="height: auto">
                                        <el-form-item v-if="editStatus" prop="ZJFXYY">
                                            <el-input  style="width: 940px;height: 36px" placeholder="请输入资金风险原因" v-model="detail.ZJFXYY"></el-input>
                                        </el-form-item>
                                        <div class="cont"  v-else>{{detail.ZJFXYY}}</div>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <div class="label2">红线占用面积</div>
                                    </td>
                                    <td>
                                        <div class="cont">{{detail.HXMJ}}</div>
                                    </td>
                                    <td>
                                        <div class="label2">农用田占地面积</div>
                                    </td>
                                    <td>
                                        <div class="cont">{{detail.NTMJ}}</div>
                                    </td>
                                </tr>
                            </table>
                            <FlowXmk v-if="SSWXMID!= null && SSWXMID!= ''" :id="SSWXMID"></FlowXmk>
                        </el-tab-pane>
                        <el-tab-pane label="总投资" name="1">
                            <table style="text-align:center">
                                <tr>
                                    <td>
                                        <div class="label2">总投资</div>
                                    </td>
                                    <td>
                                        <div class="cont">{{detail.ZTZ}}</div>
                                    </td>
                                    <td colspan="3">
                                        <div  class="label3">"中央投资(车购税)/省级补助资金"</div>
                                    </td>
                                    <td>
                                        <div class="cont">{{detail.ZYTZSJBZ}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="label2" style="line-height: unset">截止2021年底累计下达计划</div>
                                    </td>
                                    <td style="width: 220px">
                                        <div class="cont">{{detail.JZLJXDJH}}</div>
                                    </td>
                                    <td>
                                        <div class="label2">中央投资(车购税)</div>
                                    </td>
                                    <td>
                                        <div class="cont">{{detail.JZ_ZYTZ}}</div>
                                    </td>
                                    <td>
                                        <div class="label2">地方配套(大本计划)</div>
                                    </td>
                                    <td>
                                        <div class="cont">{{detail.JZ_DFPT}}</div>
                                    </td>
                                </tr>
                            </table>
                        </el-tab-pane>
                        <el-tab-pane label="计划下达" name="2">
                            <el-button  size="small"  type="primary" style="margin-bottom: 10px" icon="el-icon-edit" @click="editStatus = !editStatus">编辑</el-button>
                            <table style="text-align:center;margin-bottom: 10px">
                                <tr>
                                    <td>
                                        <div class="label2" style="width: 304px">2022投资目标(最终)</div>
                                    </td>
                                    <td>
                                        <div class="cont" style="width: 304px">{{detail.TZMBZZ}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="label2" style="width: 304px">剩余计划</div>
                                    </td>
                                    <td>
                                        <div class="cont" style="width: 304px">{{detail.SYJH}}</div>
                                    </td>
                                </tr>
                            </table>

                            <table style="text-align:center;margin-bottom: 10px">
                                <tr>
                                    <td colspan="7" style="background: #fafafa">
                                        <div class="cont" style="text-align: center;width: 100%;font-weight: 600;color: #333">公路项目建设投资计划(大本计划)</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="label2" style="width: 152px">合计</div>
                                    </td>
                                    <td>
                                        <div class="label2" style="width: 152px">地方配套</div>
                                    </td>
                                    <td>
                                        <div class="label2" style="width: 152px">财政资金</div>
                                    </td>
                                    <td>
                                        <div class="label2" style="width: 152px">专项债券</div>
                                    </td>
                                    <td>
                                        <div class="label2" style="width: 152px;line-height: unset">成品油税费改革转移支付资金</div>
                                    </td>
                                    <td>
                                        <div class="label2" style="width: 152px">银行贷款</div>
                                    </td>
                                    <td>
                                        <div class="label2">企业自筹</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <el-form-item v-if="editStatus" prop="BN_HJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                            <el-input  style="width: 145px;height: 32px" size="medium" v-model="detail.BN_HJ" placeholder="财政资金"></el-input>
                                        </el-form-item>
                                        <div  v-else class="cont2">{{detail.BN_HJ}}</div>
<!--                                        <div class="cont2">{{detail.BN_HJ}}</div>-->
                                    </td>
                                    <td>
                                        <div class="cont2">{{detail.BN_DFZC}}</div>
                                    </td>
                                    <td >
                                        <el-form-item v-if="editStatus" prop="BN_CZZJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                            <el-input  @change="changeXztotal()" style="width: 145px;height: 32px" size="medium" v-model="detail.BN_CZZJ" placeholder="财政资金"></el-input>
                                        </el-form-item>
                                        <div  v-else class="cont2">{{detail.BN_CZZJ}}</div>
                                    </td>
                                    <td>
                                        <el-form-item v-if="editStatus" prop="BN_ZXZQ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                            <el-input  @change="changeXztotal()" style="width: 145px;height: 32px" size="medium" v-model="detail.BN_ZXZQ" placeholder="专项债券"></el-input>
                                        </el-form-item>
                                        <div  v-else class="cont2">{{detail.BN_ZXZQ}}</div>
                                    </td>
                                    <td>
                                        <el-form-item v-if="editStatus" prop="BN_CPYSFGGZYZFZJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                            <el-input  @change="changeXztotal()" style="width: 145px;height: 32px" size="medium" v-model="detail.BN_CPYSFGGZYZFZJ" placeholder="成品油税费改革转移支付资金"></el-input>
                                        </el-form-item>
                                        <div  v-else class="cont2">{{detail.BN_CPYSFGGZYZFZJ}}</div>
                                    </td>
                                    <td>
                                        <el-form-item v-if="editStatus" prop="BN_YHDK" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                            <el-input  @change="changeXztotal()" style="width: 145px;height: 32px" size="medium" v-model="detail.BN_YHDK" placeholder="银行贷款"></el-input>
                                        </el-form-item>
                                        <div  v-else class="cont2">{{detail.BN_YHDK}}</div>
                                    </td>
                                    <td>
                                        <el-form-item v-if="editStatus" prop="BN_QYZC" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                            <el-input  @change="changeXztotal()" style="width: 145px;height: 32px" size="medium" v-model="detail.BN_QYZC" placeholder="企业自筹"></el-input>
                                        </el-form-item>
                                        <div  v-else class="cont2">{{detail.BN_QYZC}}</div>
                                    </td>
                                </tr>
                                </table>
                                <table style="text-align:center;margin-bottom: 10px">
                                <tr  style="background: #fafafa">
                                    <td colspan="2">
                                        <div class="cont" style="text-align: center;width:1070px;font-weight: 600;color: #333">固定资产投资计划</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 304px">
                                        <div class="label2" style="width: 304px">本次下达车购税</div>
                                    </td>
                                    <td>
<!--                                        <div class="cont">{{detail.SY_ZYTZ}}</div>-->
                                        <div class="cont">{{detail.BN_ZYCGS}}</div>
                                    </td>
                                </tr>
                                </table>
<!--                                <tr>-->
<!--                                    <td>-->
<!--                                        <div class="label2">剩余计划</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="cont">{{detail.SYJH}}</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="label2">中央投资(车购税)</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="cont">{{detail.SY_ZYTZ}}</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="label2">地方配套(大本计划)</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="cont">{{detail.SY_DFPT}}</div>-->
<!--                                    </td>-->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <td>-->
<!--                                        <div class="label2">合计</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="cont">{{detail.BN_HJ}}</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="label3">中央车购税(一批)</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <el-form-item v-if="editStatus" prop="BN_ZYCGS" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">-->
<!--                                            <el-input @change="changeXztotal()" style="width: 205px;height: 32px" size="medium" v-model="detail.BN_ZYCGS" placeholder="中央车购税(一批)"></el-input>-->
<!--                                        </el-form-item>-->
<!--                                        <div  v-else class="cont">{{detail.BN_ZYCGS}}</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div  class="label3">省级补助资金</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <el-form-item v-if="editStatus" prop="BN_SJBZZJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">-->
<!--                                            <el-input  @change="changeXztotal()" style="width: 205px;height: 32px" size="medium" v-model="detail.BN_SJBZZJ" placeholder="省级补助资金"></el-input>-->
<!--                                        </el-form-item>-->
<!--                                        <div  v-else class="cont">{{detail.BN_SJBZZJ}}</div>-->
<!--                                    </td>-->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <td>-->
<!--                                        <div class="label2">地方自筹</div>-->
<!--                                    </td>-->
<!--                                    <td style="width: 220px">-->
<!--                                        <el-form-item v-if="editStatus" prop="BN_DFZC" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">-->
<!--                                            <el-input  @change="changeXztotal()" style="width: 205px;height: 32px" size="medium" v-model="detail.BN_DFZC" placeholder="地方自筹"></el-input>-->
<!--                                        </el-form-item>-->
<!--                                        <div  v-else class="cont">{{detail.BN_DFZC}}</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="label2">财政资金</div>-->
<!--                                    </td>-->
<!--                                    <td class="w20">-->
<!--                                        <el-form-item v-if="editStatus" prop="BN_CZZJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">-->
<!--                                            <el-input  @change="changeXztotal()" style="width: 205px;height: 32px" size="medium" v-model="detail.BN_CZZJ" placeholder="财政资金"></el-input>-->
<!--                                        </el-form-item>-->
<!--                                        <div  v-else class="cont">{{detail.BN_CZZJ}}</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="label2">专项债券</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <el-form-item v-if="editStatus" prop="BN_ZXZQ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">-->
<!--                                            <el-input  @change="changeXztotal()" style="width: 205px;height: 32px" size="medium" v-model="detail.BN_ZXZQ" placeholder="专项债券"></el-input>-->
<!--                                        </el-form-item>-->
<!--                                        <div  v-else class="cont">{{detail.BN_ZXZQ}}</div>-->
<!--                                    </td>-->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <td>-->
<!--                                        <div class="label2" style="line-height: unset">成品油税费改革转移支付资金</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <el-form-item v-if="editStatus" prop="BN_CPYSFGGZYZFZJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">-->
<!--                                            <el-input  @change="changeXztotal()" style="width: 205px;height: 32px" size="medium" v-model="detail.BN_CPYSFGGZYZFZJ" placeholder="成品油税费改革转移支付资金"></el-input>-->
<!--                                        </el-form-item>-->
<!--                                        <div  v-else class="cont">{{detail.BN_CPYSFGGZYZFZJ}}</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="label2">银行贷款</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <el-form-item v-if="editStatus" prop="BN_YHDK" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">-->
<!--                                            <el-input  @change="changeXztotal()" style="width: 205px;height: 32px" size="medium" v-model="detail.BN_YHDK" placeholder="银行贷款"></el-input>-->
<!--                                        </el-form-item>-->
<!--                                        <div  v-else class="cont">{{detail.BN_YHDK}}</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="label2">企业自筹</div>-->
<!--                                    </td>-->
<!--                                    <td class="w20">-->
<!--                                        <el-form-item v-if="editStatus" prop="BN_QYZC" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">-->
<!--                                            <el-input  @change="changeXztotal()" style="width: 205px;height: 32px" size="medium" v-model="detail.BN_QYZC" placeholder="企业自筹"></el-input>-->
<!--                                        </el-form-item>-->
<!--                                        <div  v-else class="cont">{{detail.BN_QYZC}}</div>-->
<!--                                    </td>-->
<!--                                </tr>-->
                        </el-tab-pane>
                    </el-tabs>
                </el-form>
                <div class="footer" v-if="editStatus">
                    <el-button type="primary" @click="save">保存</el-button>
                    <el-button @click="cancel">取消</el-button>
                </div>
            </el-drawer>
            <el-drawer :visible.sync="assign" :title="detail.XMMC" size="80%">
                <el-form :inline="true" :model="formInline" class="demo-form-inline" style="margin-top: 42px;display:flex">
                    <div class="search">
                        <el-form-item label="年份" style="margin-right: 10px">
                            <el-date-picker
                                    v-model="formInline.year"
                                    type="year"
                                    format="yyyy 年"
                                    value-format="yyyy"
                                    placeholder="选择年">
                            </el-date-picker>
<!--                            <el-input style="width: 300px" v-model="formInline.XMMC" placeholder="请输入项目名称"></el-input>-->
                        </el-form-item>
                    </div>
                    <el-form-item>
                        <el-button type="primary" @click="getCompanyList">搜索</el-button>
                    </el-form-item>
                </el-form>
                <template>
                    <el-table :data="companyData"  border :default-sort="{ prop: 'SSDS', order: 'ascending' }" style="width: 100%">
                        <el-table-column  prop="Year" :width="100" label="年度" align="center" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column  prop="CGS" label="固定资产投资计划" align="center" show-overflow-tooltip>
                            <el-table-column  prop="CGS" label="车购税" align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column  prop="WH1" :width="300" align="center" label="车购税文号" show-overflow-tooltip ></el-table-column>
                        </el-table-column>
                        <el-table-column  prop="CGS" label="建设投资计划(大本计划)" align="center" show-overflow-tooltip>
                            <el-table-column  prop="HJ" label="合计" align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column  prop="DFPT" label="地方配套" align="center" show-overflow-tooltip ></el-table-column>
                            <el-table-column  prop="CZZJ" label="财政资金" align="center" show-overflow-tooltip ></el-table-column>
                            <el-table-column  prop="ZXZQ" label="专项债券" align="center" show-overflow-tooltip ></el-table-column>
                            <el-table-column  prop="YHDK" :width="220" align="center" label="成品油税费改革转移支付资金" show-overflow-tooltip ></el-table-column>
                            <el-table-column  prop="CPY" label="银行贷款" align="center" show-overflow-tooltip ></el-table-column>
                            <el-table-column  prop="QYZC" label="企业自筹" align="center" show-overflow-tooltip ></el-table-column>
                            <el-table-column  prop="WH2" label="文号" :width="220" align="center" show-overflow-tooltip ></el-table-column>
                        </el-table-column>
                        <el-table-column width="150"  prop="name" label="附件" align="center">
                            <template slot-scope="scope">
                                <el-popover trigger="hover" placement="top" v-if="scope.row.FJ.length > 0">
                                    <ul class="fj-list">
                                        <li v-for="(item,i) in scope.row.FJ" :key="i">
                                            <a target="view_window" :href="baseUrl+item.url+item.name">
                                                {{item.name}}
                                            </a>
                                            <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                        </li>
                                    </ul>

                                    <div slot="reference" class="name-wrapper">
                                        <el-tag :type="scope.row.FJ.length>0?'success':'danger'" size="small">已上传{{scope.row.FJ.length}}个</el-tag>
                                    </div>
                                </el-popover>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
                <template #footer>
      <span class="dialog-footer">
        <el-button @click="assign = false">取消</el-button>
      </span>
                </template>
            </el-drawer>

            <el-drawer :visible.sync="showSswxm" @close="closeDrawer1" title="对应部十四五交通运输专项建设规划项目" size="80%">
                <el-form :inline="true" :model="sswform" class="demo-form-inline" style="margin-top: 42px;display:flex">
                    <div class="search">
                        <el-form-item label="项目名称">
                            <el-input style="width: 300px; margin-right: 10px" v-model="sswform.XMMC" placeholder="请输入项目名称"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item>
                        <el-button type="primary" @click="getSWWXMList()">搜索</el-button>
                        <el-button type="primary" @click="confirmCompany">确定</el-button>
                    </el-form-item>
                </el-form>
                <template>
                    <el-table :header-cell-class-name="cellClass" @selection-change="selectCompany"  ref="multipleTable" :data="sswxmList"  border :default-sort="{ prop: 'SSDS', order: 'ascending' }" style="width: 100%">
                        <el-table-column fixed type="selection" width="55" />
                        <el-table-column fixed prop="SSDS" label="所在地市" :width="100" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column fixed prop="SSX" label="所属县（市）" :width="150" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column fixed prop="XMMC" label="项目名称" show-overflow-tooltip :width="320" sortable></el-table-column>
                        <el-table-column prop="LXBH" label="路线编号" :width="150" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="XMXZ" label="项目性质" :width="150" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="JSXZ" label="建设性质" :width="150" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="XMLX" label="项目类型" :width="150" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="GLLX" label="建设类别" :width="150" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="KGN" label="开工年" :width="150" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="WGN" label="完工年" :width="150" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="JSGMGS" label="建设规模高速（公里）" :width="170" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="JSGMYJ" label="建设规模一级（公里）" :width="170" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="JSGMEJ" label="建设规模二级（公里）" :width="170" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="JSGMSJ" label="建设规模三级（公里）" :width="170" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="JSGMQS" label="建设规模桥隧（数量）" :width="170" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="ZTZ" label="总投资" :width="150" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="ZYCGS" label="中央车购税" :width="150" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="SAWZTJ" label="“十三五”投资（亿元）" :width="170" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="SAWZYCGS" label="“十三五”中央车购税（亿元）" :width="210" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="SSWZTZ" label="“十四五”投资" :width="160" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="SSWZYCGS" label="“十四五”中央车购税" :width="210" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="SSWSJZJ" label="“十四五”省级资金" :width="170" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="SSWSXZCTZ" label="“十四五”市县征拆投资" :width="170" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="SWWZTZ" label="“十五五”投资" :width="160" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="SWWZYCGS" label="“十五五”中央车购税" :width="170" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="SFYDSXW" label="是否地市行文" :width="160" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="XMLY" label="项目来源" :width="160" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="QQGZJZ" label="前期工作进展" :width="160" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="BZ" label="备注" show-overflow-tooltip></el-table-column>
                    </el-table>
                    <el-pagination background @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="currentPage1" :page-sizes="[10, 20, 30,40]" :page-size="pageSize1" layout="total, sizes, prev, pager, next, jumper" :total="total1"></el-pagination>
                    <!--                <el-pagination background @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="currentPage1" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize1" layout="total, sizes, prev, pager, next, jumper" :total="total1"></el-pagination>-->
                </template>
                <template #footer>
      <span class="dialog-footer">
        <el-button @click="assign = false">取消</el-button>
      </span>
                </template>
            </el-drawer>
        </div>
        <el-dialog
                :visible.sync="showMessage"
                title="提示"
                width="30%"
        >
            <span>当前页面未保存,是否确定退出?</span>
            <template #footer>
      <span class="dialog-footer">
        <el-button @click="showMessage = false">取消</el-button>
        <el-button type="primary" @click="closeEdit">确认</el-button>
      </span>
            </template>
        </el-dialog>
        <el-dialog :title="dialogTableVisibleData.XMMC" :visible.sync="dialogTableVisibleMap" class="mapdialog mapdialog1">
            <Map v-if="dialogTableVisibleMap" :dialogData="dialogTableVisibleData" />
        </el-dialog>
        <el-dialog :title="dialogTableVisibleData1.XMMC" :visible.sync="dialogTableVisibleMap1" class="mapdialog mapdialog1">
            <LKPD v-if="dialogTableVisibleMap1" :dialogData="dialogTableVisibleData1" />
        </el-dialog>

    </div>
</template>
<script>
    import FlowXmk from "../../components/Query/Road/GhkFlow";
    import Map from "../AMap/Box1.vue";
    import LKPD from "../AMap/Box2.vue";
    import { exportData } from "../../js/util/export.js"; //导出表格
    import axios from "axios";
    import store from '../../store/index'
    // import Flow from "../../components/Query/Road/Flow";
    import http from "../../api/http";
    // import FlowTable from "../../components/Query/Road/FlowTable";
    export default {
        name: "Home",
        components: {FlowXmk,Map,LKPD },
        data() {
            return {
                dialogTableVisibleData:{},
                dialogTableVisibleMap: false,
                dialogTableVisibleData1:{},
                dialogTableVisibleMap1: false,
                showSswxm: false,
                SSWXMID:'',
                editStatus: false,
                activeName:'0',
                required: false,
                innerDrawer: false,
                title:'新增建设单位',
                ruleForm:{},
                chekfr:[{XMZRR:'',XMZRRLXDH:''}],
                confirmXMFR:[{XMZRR:'',XMZRRLXDH:''}],
                XMFRDW:'',
                XMFRDWID:'',
                frCompany:false,
                frCompanyList:[],
                frdw:'',
                frForm:{},
                formInline:{},
                sswform:{},
                assign: false,
                showAddModel: false,
                lineHeight:500,
                uploadLoading:false,
                detail:{
                    jsxmydys:{pfwj:[]},
                    ptzjcns:{pfwj:[]},
                    stbcpjpf:{pfwj:[]},
                    ghxzyjs:{pfwj:[]},
                    hjyxpjpf:{pfwj:[]},
                    jnpg:{pfwj:[]},
                    shwd:{pfwj:[]},
                    xmjy:{pfwj:[]},
                    gky:{pfwj:[]},
                    cbsj:{pfwj:[]},
                    sgt:{pfwj:[]},
                    zsyd:{pfwj:[]},
                    sgxk:{pfwj:[]},
                    jtb:{pfwj:[]},
                    qt:{pfwj:[]}
                },
                showDetail: false,
                showMessage:false,
                baseUrl: "http://82.156.50.94:8002/",
                gkbz:false,
                singleData:{
                    wlyd:0,
                    nyd:0
                },
                XMMC:'',
                addStatus: true,
                Collapse:['1','2','3'],
                step:['一、工可','二、初步设计','三、施工图设计','四、施工前准备','五、县域国土空间规划'],
                activeProcess:0,
                showEditModel: false,
                confirmSSW:[],
                tableHeight:0, //表格高度
                activeIndex: true, //显隐高级搜索
                /**查询条件 begin*/
                xmmc: "", //项目名称
                xmnd: "", //项目年度
                gllx: "", //建设类别
                checkedXmxz: [], //项目性质
                checkedJsxz: [], //建设性质
                checkedXmlx: [], //项目类型
                checkedXmlb: [], //项目类别
                checkedJsgm: [], //项目类别
                checkedGk: [], //项目类别
                checkedFxxm: [], //项目类别
                checkedSgtsj: [], //项目类别
                checkedCbsj: [], //项目类别
                region: [""], //已选中行政区划
                qqgzjz: [], //前期工作进展
                /**查询条件 end*/
                xmxzShow: true, //项目性质查询条件显隐
                jsxzShow: true, //建设性质查询条件显隐
                xmlxShow: true, //项目类型查询条件显隐
                checkList: [],
                checkAllXmlb: false,
                checkAllXmxz: false,
                checkAllJsxz: false,
                checkAllXmlx: false,
                checkAllJsgm: false,
                checkAllGk: false,
                checkAllCbsj: false,
                checkAllSgtsj: false,
                xmxzList: [
                    {
                        value: "XKG",
                        label: "新开工项目",
                        disabled: false,
                    },
                    {
                        value: "XJ",
                        label: "续建项目",
                        disabled: false,
                    },
                ],
                zzfxyy: false,
                jsxzList: ["新建", "升级改造", "原级改造", "路面改造", "改扩建"],
                xmlxList: ["正选项目", "备选项目"],
                jsgmList: [{value: "JSGMGS", label: "高速", disabled: false},{value: "JSGMYJ", label: "一级", disabled: false},{value: "JSGMEJ", label: "二级", disabled: false},{value: "JSGMSJ", label: "三级", disabled: false},{value: "JSGMTDQ", label: "特大桥", disabled: false},{value: "JSGMSD", label: "隧道", disabled: false}],
                xmlbList: [{value: "审批", label: "审批", disabled: false},{value: "核准", label: "核准", disabled: false}],
                gkList: [{value: "0", label: "未开展", disabled: false},{value: "1", label: "办理中", disabled: false},{value: "2", label: "已办结", disabled: false}],
                cbsjList: [{value: "0", label: "未开展", disabled: false},{value: "1", label: "办理中", disabled: false},{value: "2", label: "已办结", disabled: false}],
                sgtsjList: [{value: "0", label: "未开展", disabled: false},{value: "1", label: "办理中", disabled: false},{value: "2", label: "已办结", disabled: false}],
                fxxmList: [{value: "是", label: "是", disabled: false},{value: "否", label: "否", disabled: false}],
                currentPage: 1, //当前页
                currentPage1: 1, //当前页
                pageSize: 30, //每页记录数
                pageSize1: 10, //每页记录数
                isIndeterminateXmxz: true,
                isIndeterminateJsgm: true,
                isIndeterminateGk: true,
                isIndeterminateCbsj: true,
                isIndeterminateSgtsj: true,
                isIndeterminateJsxz: true,
                isIndeterminateXmlx: true,
                isIndeterminateXmlb: true,
                SSWSSWGHXMID:'',
                oldYearId:'',
                regions: [
                    {
                        value: "",
                        label: "黑龙江省",
                    },
                ],
                tableData: [], //请求列表
                total: 0, //请求记录数
                total1: 0, //请求记录数
                totalSqu:0,
                companyData:[],
                searchAll:[],
                chek:[],
                newAdd:false,
                editModel:{},
                showEdit:false,
                qqjzid:'',
                innerDrawerTitle:'',
                gg: '',
                GKWCD:'',
                CBSJWCD:'',
                SGTSJWCD:'',
                SGQZBWCD:'',
                isAdmin: false,
                rowData:{},
                sortData: {
                    field: "XMXH",
                    sort: "asc",
                },
                sswxmList:[],
            };
        },
        mounted() {
            var that = this
            let height = this.$refs.init.offsetHeight;
            setTimeout(() => {
                that.tableHeight = document.body.offsetHeight - height - 150;
            }, 100);
            this.getRegion();
            this.search();
            this.getSWWXMList()
            this.http.post('/api/Sys_User/getCurrentUserInfo',{}).then(res=>{
                this.isAdmin = res.data.roleName == '超级管理员' ||  res.data.isMain  == 1 ? true : false
            })
        },
        computed:{

        },
        methods: {
            tableRowClassName({ row, rowIndex }) {
                if (row.ZJFX === '是' || row.ZDFX === '是') {
                    return 'warning-row'
                }
                return ''
            },
            changeZzfx(val){
                console.log(val)
                if(val === '是'){
                    this.zzfxyy = true
                }else{
                    this.detail.ZJFXYY = ''
                    this.zzfxyy = false
                }
            },
            showMaps(row) {
                if(row.TXMMC){
                    this.dialogTableVisibleData = row;
                    this.dialogTableVisibleMap = true;
                }
            },
            showMaps1(row) {
                if(row.TXMMC) {
                    this.dialogTableVisibleData1 = row;
                    this.dialogTableVisibleMap1 = true;
                }
            },
            changeSort(val) {
                console.log(val)
                if (val.order) {
                    this.sortData = {
                        field: val.prop,
                        sort: val.order == "ascending" ? "asc" : "desc",
                    };
                    if (val.prop == "SZDS") {
                        this.sortData.field = "DSBM";
                    }
                    if (val.prop == "SZX") {
                        this.sortData.field = "QXBM";
                    }
                } else {
                    this.sortData = {
                        field: "XMXH",
                        sort: "asc",
                    };
                }
                this.tableData = [];
                this.search();
            },
            handleClick(tab, event) {
                console.log(tab, event);
            },
            closeDrawer(){
                this.$refs.detailForm.resetFields()
                this.detail = {}
                console.log('关闭了')
                this.confirmSSW = []
                this.XMMC = ''
            },
            closeDrawer1(){
                this.currentPage1 = 1
            },
            cellClass(row){
                if (row.columnIndex === 0) {
                    return 'DisableSelection'
                }
            },
            accAdd(arg1, arg2) {
                var r1, r2, m;
                try { r1 = arg1.toString().split(".")[1].length; } catch (e) { r1 = 0; }
                try { r2 = arg2.toString().split(".")[1].length; } catch (e) { r2 = 0; }
                m = Math.pow(10, Math.max(r1, r2));
                return (arg1 * m + arg2 * m) / m;
            },
            changeXztotal(e,type){
                var reg =  /^(?:[1-9]\d*|0)(?:\.\d+)?$/
                var xz1 = this.detail.BN_ZYCGS == null || this.detail.BN_ZYCGS ==  '' || !reg.test(this.detail.BN_ZYCGS) ? 0 : parseFloat(this.detail.BN_ZYCGS)
                var xz2 = this.detail.BN_SJBZZJ == null || this.detail.BN_SJBZZJ ==  '' || !reg.test(this.detail.BN_SJBZZJ) ? 0 : parseFloat(this.detail.BN_SJBZZJ)
                var xz3 = this.detail.BN_DFZC == null || this.detail.BN_DFZC ==  '' || !reg.test(this.detail.BN_DFZC) ? 0 : parseFloat(this.detail.BN_DFZC)
                var xz4 = this.detail.BN_CZZJ == null || this.detail.BN_CZZJ ==  '' || !reg.test(this.detail.BN_CZZJ) ? 0 : parseFloat(this.detail.BN_CZZJ)
                var xz5 = this.detail.BN_ZXZQ == null || this.detail.BN_ZXZQ ==  '' || !reg.test(this.detail.BN_ZXZQ) ? 0 : parseFloat(this.detail.BN_ZXZQ)
                var xz6 = this.detail.BN_CPYSFGGZYZFZJ == null || this.detail.BN_CPYSFGGZYZFZJ ==  '' || !reg.test(this.detail.BN_CPYSFGGZYZFZJ) ? 0 : parseFloat(this.detail.BN_CPYSFGGZYZFZJ)
                var xz7 = this.detail.BN_YHDK == null || this.detail.BN_YHDK ==  '' || !reg.test(this.detail.BN_YHDK) ? 0 : parseFloat(this.detail.BN_YHDK)
                var xz8 = this.detail.BN_QYZC == null || this.detail.BN_QYZC ==  '' || !reg.test(this.detail.BN_QYZC) ? 0 : parseFloat(this.detail.BN_QYZC)
                this.detail.BN_HJ = this.accAdd(this.accAdd(this.accAdd(this.accAdd(this.accAdd(this.accAdd(this.accAdd(xz1,xz2),xz3),xz4),xz5),xz6),xz7),xz8)
                if(this.detail.BN_HJ > 100){
                    this.$message.warning('超出本年最终投资目标,请谨慎填写')
                }
                // this.detail.xzhj = (xz1*1000/1000 +xz2*1000/1000 + xz3*1000/1000 +xz4*1000/1000 +xz5*1000/1000 + xz6*1000/1000 + xz7*1000/1000 + xz8*1000/1000)*1000/1000
            },
            changeJstotal(e,type){
                var reg =  /^(?:[1-9]\d*|0)(?:\.\d+)?$/
                var xz1 = this.detail.GM_YJGL == null || this.detail.GM_YJGL ==  '' || !reg.test(this.detail.GM_YJGL) ? 0 : parseFloat(this.detail.GM_YJGL)
                var xz2 = this.detail.GM_EJGL80 == null || this.detail.GM_EJGL80 ==  '' || !reg.test(this.detail.GM_EJGL80) ? 0 : parseFloat(this.detail.GM_EJGL80)
                var xz3 = this.detail.GM_EJGL60 == null || this.detail.GM_EJGL60 ==  '' || !reg.test(this.detail.GM_EJGL60) ? 0 : parseFloat(this.detail.GM_EJGL60)
                var xz4 = this.detail.GM_SANJGL == null || this.detail.GM_SANJGL ==  '' || !reg.test(this.detail.GM_SANJGL) ? 0 : parseFloat(this.detail.GM_SANJGL)
                var xz5 = this.detail.GM_SIJGL == null || this.detail.GM_SIJGL ==  '' || !reg.test(this.detail.GM_SIJGL) ? 0 : parseFloat(this.detail.GM_SIJGL)
                var xz6 = this.detail.GM_DLDQ == null || this.detail.GM_DLDQ ==  '' || !reg.test(this.detail.GM_DLDQ) ? 0 : parseFloat(this.detail.GM_DLDQ)
                var xz7 = this.detail.GM_GSGL == null || this.detail.GM_GSGL ==  '' || !reg.test(this.detail.GM_GSGL) ? 0 : parseFloat(this.detail.GM_GSGL)
                this.detail.GM_HJ = this.accAdd(this.accAdd(this.accAdd(this.accAdd(this.accAdd(this.accAdd(xz1,xz2),xz3),xz4),xz5),xz6),xz7)
            },
            //保存
            saveCompany(){
                this.$refs.editForm.validate((valid) => {
                    if (valid) {
                        this.newAdd ?  this.add(this.editModel) : this.update(this.editModel)

                    } else {

                        return false;
                    }
                });
            },
            //新增的
            add(params){
                params.id = '00000000-0000-0000-0000-000000000000'
                this.http.post('/api/Plan_buildcompany/AddBuildcompany?xmlx=0',params).then(res=>{
                    if(res.status){
                        this.$message.success(res.message)
                        this.getFRDW()
                        this.editModel = {}
                        this.$refs.editForm.resetFields()
                        this.showEdit = false
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            //编辑的
            update(params){
                this.http.post('/api/Plan_buildcompany/UpdateBuildcompany?xmlx=0',params).then(res=>{
                    if(res.status){
                        this.$message.success(res.message)
                        this.getFRDW()
                        this.editModel = {}
                        this.$refs.editForm.resetFields()
                        this.showEdit = false
                    }else{
                        this.$message.error(res.message)
                    }
                })

            },
            //删除
            deletedCompany(item){
                this.http.post('/api/Plan_buildcompany/DeleteBuildcompany',item).then(res=>{
                    if(res.status){
                        this.$message.success(res.message)
                        this.getFRDW()
                        this.editModel = {}
                        this.$refs.editForm.resetFields()
                        this.showEdit = false
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            confirmCompany(){
                this.confirmSSW = JSON.parse(JSON.stringify(this.chek))
                console.log(this.confirmSSW)
                if (this.chek.length != 0 ){
                    this.XMMC = this.chek[0].XMMC
                    this.SSWSSWGHXMID =this.chek[0].ID
                }else{
                    this.XMMC = ''
                    this.SSWSSWGHXMID = ''
                }
                this.showSswxm = false
                this.sswform = {}
                this.currentPage1 = 1
            },
            //编辑
            edit(item){
                this.newAdd = false
                this.title = '修改建设单位'
                // this.editModel = JSON.parse(JSON.stringify(item))
                this.showEdit = true
            },
            addCompany(){
                this.newAdd = true
                this.title = '新增建设单位'
                this.editModel = {}
                this.showEdit = true
            },
            checkZZS  (rule, value, callback)  {
                if (!value) {
                    return callback(new Error('所填项不能为空'));
                }
                var reg =  /^[+]{0,1}(\d+)$/
                setTimeout(() => {
                    if (!reg.test(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        callback();
                    }
                }, 0);
            },
            checkNum  (rule, value, callback)  {
                if (!value) {
                    callback();
                }
                var reg =  /^\d+(\.\d+)?$/
                setTimeout(() => {
                    if (value && !reg.test(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        callback();
                    }
                }, 0);
            },
            checkEdit(rule, value, callback){
                console.log(this.newAdd)
                if (this.newAdd == false){
                    callback();
                }else{
                    if (!value) {
                        callback(new Error('请输入当前内容'));
                    }else{
                        callback();
                    }
                }
            },
            checkNull(rule, value, callback){
                console.log(this.newAdd)
                // if (this.newAdd == false){
                //
                //     return false
                // }
                console.log(value)
                if (!value) {
                    callback(new Error('请输入当前内容'));
                }else{
                    callback();
                }
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        alert('submit!');
                    } else {
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            download(url, fileName) {
                //下载导出的文件
                let xmlResquest = new XMLHttpRequest();
                xmlResquest.open("GET", url, true);
                xmlResquest.setRequestHeader("Content-type", "application/json");
                xmlResquest.setRequestHeader(
                    "Authorization",
                    store.getters.getToken()
                );
                let elink = document.createElement("a");
                xmlResquest.responseType = "blob";
                xmlResquest.onload = function(oEvent) {
                    if (xmlResquest.status != 200) {
                        this.$error("下载文件出错了..");
                        return;
                    }
                    let content = xmlResquest.response;
                    elink.download = fileName; //+".xlsx";
                    // elink.style.display = "none";
                    let blob = new Blob([content]);
                    elink.href = URL.createObjectURL(blob);
                    // document.body.appendChild(elink);
                    elink.click();
                    //  document.body.removeChild(elink);
                };
                xmlResquest.send();
            },
            downLoadZip(row){
                var that = this
                this.http.get('/api/Plan_high_national_early/getFilePackage?id='+row.ID,{}).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        name+'.zip'
                    );
                })
            },
            showFr(){
                var that = this
                if(!this.isAdmin ){
                    this.$message.warning('暂无此权限')
                    return false
                }
                this.getFRDW()
                this.frCompany = true
                if(this.addStatus){
                    console.log(this.confirmXMFR.length,this.confirmXMFR[0].XMZRR)
                    if ( this.confirmXMFR.length != 0 && this.confirmXMFR[0].XMZRR == ''){
                        this.$nextTick(()=>{
                            this.$refs.multipleTable1.clearSelection()
                        })
                    }else{
                        setTimeout(()=> {
                            this.$nextTick(() => {
                                console.log('jinlaile2')
                                that.$refs.multipleTable1.toggleRowSelection(that.frCompanyList.find((item) => {
                                    console.log(1212121,that.XMFRDWID,'JINLAILE3')
                                    if (item.ID == that.XMFRDWID) {
                                        console.log(item)
                                        return item
                                    }
                                }))
                            })
                        },1000)

                    }

                }else {
                    if (this.confirmXMFR.length != 0 && this.confirmXMFR[0].XMZRR != ''){
                        setTimeout(()=> {
                            this.$nextTick(() => {
                                that.$refs.multipleTable1.toggleRowSelection(that.frCompanyList.find((item) => {
                                    if (item.ID == that.XMFRDWID) {
                                        return item
                                    }
                                }))
                            }, 1000)
                        })
                    }
                }
            },
            //搜索条件
            searchData(){
                var searchData = {
                    page: this.currentPage1,
                    rows: this.pageSize1,
                    wheres:JSON.stringify([{Name:'XMLX',Value:'Road',DisplayType:'Equal'}]),
                };
                return searchData
            },
            chooseXm(row){
                var that = this
                this.sswform = {}
                this.showSswxm = true
                console.log(this.confirmSSW)
                this.getSWWXMList()
                if( this.confirmSSW.length == 0){
                    this.$nextTick(()=>{
                        this.$refs.multipleTable.clearSelection()
                    })
                }else{
                    setTimeout(()=>{
                        this.$nextTick(()=>{
                            that.$refs.multipleTable.toggleRowSelection(that.sswxmList.find((item) => {
                                    if (item.XMMC == that.confirmSSW[0].XMMC){
                                        return item
                                    }
                                }),
                                true);
                        })
                    },0)

                    // that.$refs.multipleTable.toggleRowSelection(that.chek[0],true);

                }
            },
            selectCompany(val){
                console.log(val[0])
                var that = this
                if(val.length > 1){
                    this.$refs.multipleTable.clearSelection()
                    this.$refs.multipleTable.toggleRowSelection(val.pop())
                }else{
                    this.chek = val;
                }
            },
            getSWWXMList(){
                var that = this
                this.http.post('/api/Plan_high_national_early/queryPlanRoad', this.postData2()).then(res=> {
                    this.sswxmList = res.rows
                    this.total1 = res.total
                    this.$nextTick(()=>{
                        if(that.confirmSSW.length != 0){

                        that.$refs.multipleTable.toggleRowSelection(that.sswxmList.find((item) => {
                                if (item.XMMC == that.confirmSSW[0].XMMC){
                                    return item
                                }
                            }),
                            true);
                        }
                    })
            })
            },
            openTable(row){
                this.assign = true
                this.currentPage1 = 1
                this.oldYearId = row.ID
                this.getCompanyList()
            },
            getCompanyList(){
                var that = this
                this.http.post('/api/View_Plan_road_investment_old_year/GetPageData',this.postData1()).then(res=> {
                    this.companyData = res.rows
                    this.companyData.map((item,index)=>{
                        var a = []
                        if(item.FJ != '' && item.FJ != null){
                            item.FJ = item.FJ.split(';')
                            item.FJ.map((subItem)=>{
                                subItem = {url:subItem.substring(0,subItem.lastIndexOf("/") + 1),name:subItem.substring(subItem.lastIndexOf("/") + 1,subItem.length)}
                                a.push(subItem)
                            })
                            item.FJ = a
                        }else {
                            item.FJ = []
                        }

                    })
                })
            },
            //退出编辑
            closeEdit(){
                this.showMessage = false
                this.$refs.detailForm.resetFields()
                this.editStatus = false
                this.zzfxyy = false
            },
            //删除图片
            deleted(index,type){
                this.detail[type].pfwj.splice(index,1)
            },
            activeChange(e){
            },
            changeTotal(val,type){
                var that = this
                var reg =  /^(?:[1-9]\d*|0)(?:\.\d+)?$/
                if (val && !reg.test(val)) {
                    return false
                }
                if (type == 'nyd'){
                    if(this.detail.ydwlyd != '' && this.detail.ydwlyd != null){
                        this.totalSqu = val == '' ? that.accAdd(0,parseFloat(this.detail.ydwlyd))  :  that.accAdd( parseFloat(val) ,  parseFloat(this.detail.ydwlyd))
                    }else{
                        this.totalSqu = val == '' ? 0 : parseFloat(val)*1000/1000
                    }
                }else{
                    if(this.detail.ydnyd != '' && this.detail.ydnyd != null){
                        this.totalSqu =  val == '' ? that.accAdd(0 , parseFloat(this.detail.ydnyd)) : that.accAdd(parseFloat(val)  , parseFloat(this.detail.ydnyd))
                    }else{
                        this.totalSqu = val == '' ? 0: parseFloat(val)
                    }
                }

            },
            setTimes(time) {
                if (!time) return time;
                let dt = new Date(time);
                let yyyy = dt.getFullYear();
                let MM = (dt.getMonth() + 1).toString().padStart(2, "0");
                let dd = dt.getDate().toString().padStart(2, "0");
                return yyyy + "-" + MM + "-" + dd;
            },
            save(){
                this.$refs.detailForm.validate((valid) => {
                    var reg =  /^(?:[1-9]\d*|0)(?:\.\d+)?$/
                    if (valid) {
                        var params = JSON.parse(JSON.stringify(this.detail))
                        if(this.confirmSSW.length >0){
                            params.XMID = this.confirmSSW[0].ID
                        }
                        params.GM_GSGL =  params.GM_GSGL== null || params.GM_GSGL ==  '' || !reg.test(params.GM_GSGL) ? 0 : parseFloat(params.GM_GSGL)
                        params.GM_YJGL =  params.GM_YJGL== null || params.GM_YJGL ==  '' || !reg.test(params.GM_YJGL) ? 0 : parseFloat(params.GM_YJGL)
                        params.GM_EJGL60 =  params.GM_EJGL60== null || params.GM_EJGL60 ==  '' || !reg.test(params.GM_EJGL60) ? 0 : parseFloat(params.GM_EJGL60)
                        params.GM_EJGL80 =  params.GM_EJGL80== null || params.GM_EJGL80 ==  '' || !reg.test(params.GM_EJGL80) ? 0 : parseFloat(params.GM_EJGL80)
                        params.GM_SANJGL =  params.GM_SANJGL== null || params.GM_SANJGL ==  '' || !reg.test(params.GM_SANJGL) ? 0 : parseFloat(params.GM_SANJGL)
                        params.GM_SIJGL =  params.GM_SIJGL== null || params.GM_SIJGL ==  '' || !reg.test(params.GM_SIJGL) ? 0 : parseFloat(params.GM_SIJGL)
                        params.GM_DLDQ =  params.GM_DLDQ== null || params.GM_DLDQ ==  '' || !reg.test(params.GM_DLDQ) ? 0 : parseFloat(params.GM_DLDQ)
                        params.BN_HJ =  params.BN_HJ== null || params.BN_HJ ==  '' || !reg.test(params.BN_HJ) ? 0 : parseFloat(params.BN_HJ)
                        params.BN_ZYCGS =  params.BN_ZYCGS== null || params.BN_ZYCGS ==  '' || !reg.test(params.BN_ZYCGS) ? 0 : parseFloat(params.BN_ZYCGS)
                        params.BN_SJBZZJ =  params.BN_SJBZZJ== null || params.BN_SJBZZJ ==  '' || !reg.test(params.BN_SJBZZJ) ? 0 : parseFloat(params.BN_SJBZZJ)
                        params.BN_DFZC =  params.BN_DFZC== null || params.BN_DFZC ==  '' || !reg.test(params.BN_DFZC) ? 0 : parseFloat(params.BN_DFZC)
                        params.BN_CZZJ =  params.BN_CZZJ== null || params.BN_CZZJ ==  '' || !reg.test(params.BN_CZZJ) ? 0 : parseFloat(params.BN_CZZJ)
                        params.BN_ZXZQ =  params.BN_ZXZQ== null || params.BN_ZXZQ ==  '' || !reg.test(params.BN_ZXZQ) ? 0 : parseFloat(params.BN_ZXZQ)
                        params.BN_CPYSFGGZYZFZJ =  params.BN_CPYSFGGZYZFZJ== null || params.BN_CPYSFGGZYZFZJ ==  '' || !reg.test(params.BN_CPYSFGGZYZFZJ) ? 0 : parseFloat(params.BN_CPYSFGGZYZFZJ)
                        params.BN_YHDK =  params.BN_YHDK== null || params.BN_YHDK ==  '' || !reg.test(params.BN_YHDK) ? 0 : parseFloat(params.BN_YHDK)
                        params.BN_QYZC =  params.BN_QYZC== null || params.BN_QYZC ==  '' || !reg.test(params.BN_QYZC) ? 0 : parseFloat(params.BN_QYZC)
                        console.log(params)
                        this.http.post('/api/Plan_road_investment/UpdatePlanRoadInvestment',params).then(res=>{
                            if(res.status){
                                this.showAddModel = false
                                this.search()
                                this.$message.success(res.message)
                            }else{
                                this.$message.error(res.message)
                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
            cancel(){
                this.showMessage = true
                this.showEdit = false

            },
            onSubmit(e, type) {
                this.uploadLoading = true
                let file = e.target.files;
                console.log(file)
                let form = new FormData();
                let arrs = [];
                console.log(file);
                file.forEach((element) => {
                    form.append("fileInput", element);
                    arrs.push(element.name);
                });
                this.http.post("/api/Plan_high_national_early/upload", form)
                    .then((res) => {
                        this.uploadLoading = false
                        arrs.forEach((v) => {
                            // this.details[type].push(res.data + v);
                            this.detail[type].pfwj.push({url:res.data, name:v})

                        });
                        // this.detail[type].pfwj.push({url:res.data, name:file[0].name})
                    });

            },
            changeCollapse(val){
            },
            chechfieldxmlx() {
                return this.gllx == "普通省道" ? false : true;
            },
            chechfieldxmlb() {
                return this.gllx == "普通省道" ? false : true;
            },
            checkfieldjsxz() {
                return this.gllx == "高速公路" ? true : false;
            },
            checkfieldxmxz(e) {
                var arr = [];
                if (this.gllx == "高速公路") {
                    arr = [
                        "“十三五”续建项目",
                        "2021年新开工项目",
                        "“十四五”其他项目",
                        "“十四五”谋划项目",
                    ];
                    return arr.indexOf(e) >= 0 ? false : true;
                }
                if (this.gllx == "普通国道") {
                    arr = [
                        "“十三五”续建项目",
                        "“十四五”新开工项目",
                        "谋划项目",
                        "“十四五”谋划项目",
                    ];
                    return arr.indexOf(e) >= 0 ? false : true;
                }
                if (this.gllx == "普通省道") {
                    arr = [
                        "“十三五”续建项目",
                        "2021年已安排计划项目",
                        "2022年建设项目",
                        "2023年建设项目",
                        "2024-2025年建设项目",
                    ];
                    return arr.indexOf(e) >= 0 ? false : true;
                }
            },
            closeDetail(){
                this.showDetail = false
                this.detail = {}

            },
            openDetailsView(row) {
                this.showDetail = true
                this.detail = row
                this.detail.gkbzwj = this.detail.gkbzwj == null || this.detail.gkbzwj.length === 0 ? [] :JSON.parse(this.detail.gkbzwj)
                this.detail.gkpsyj = this.detail.gkpsyj == null|| this.detail.gkbzwj.gkpsyj === 0  ? [] :JSON.parse(this.detail.gkpsyj)
                this.detail.gkpfwj = this.detail.gkpfwj == null || this.detail.gkbzwj.gkpfwj === 0 ? [] :JSON.parse(this.detail.gkpfwj)
                this.detail.cbsjbzwj = this.detail.cbsjbzwj == null|| this.detail.gkbzwj.cbsjbzwj === 0  ? [] :JSON.parse(this.detail.cbsjbzwj)
                this.detail.cbsjpswj = this.detail.cbsjpswj == null|| this.detail.gkbzwj.cbsjpswj === 0  ? [] :JSON.parse(this.detail.cbsjpswj)
                this.detail.cbsjpfwj = this.detail.cbsjpfwj == null || this.detail.gkbzwj.cbsjpfwj === 0 ? [] :JSON.parse(this.detail.cbsjpfwj)
                this.detail.sgtsjbzwj = this.detail.sgtsjbzwj == null|| this.detail.gkbzwj.sgtsjbzwj === 0  ? [] :JSON.parse(this.detail.sgtsjbzwj)
                this.detail.sgtsjpswj = this.detail.sgtsjpswj == null || this.detail.gkbzwj.sgtsjpswj === 0 ? [] :JSON.parse(this.detail.sgtsjpswj)
                this.detail.sgtsjpfwj = this.detail.sgtsjpfwj == null|| this.detail.gkbzwj.sgtsjpfwj === 0  ? [] :JSON.parse(this.detail.sgtsjpfwj)
                this.detail.sgqzbydpfwj = this.detail.sgqzbydpfwj == null || this.detail.gkbzwj.sgqzbydpfwj === 0 ? [] :JSON.parse(this.detail.sgqzbydpfwj)
                this.detail.sgqzbsgxdpfwj = this.detail.sgqzbsgxdpfwj == null || this.detail.gkbzwj.sgqzbsgxdpfwj === 0 ? [] :JSON.parse(this.detail.sgqzbsgxdpfwj)
                this.detail.xygtkjghwj = this.detail.xygtkjghwj == null|| this.detail.gkbzwj.xygtkjghwj === 0  ? [] :JSON.parse(this.detail.xygtkjghwj)
            },
            getZD(XMMC){
                this.http.post('/api/Plan_road_land_space/GetPageData',
                    {rows:100,
                        Wheres:JSON.stringify([{Name:'XMMC',Value:XMMC,DisplayType: "like"}])
                    }).then(res=>{
                    this.zdInfo = res.rows[0]
                })
        },
           async openDetailsEdit(row) {
                var that = this
                // console.log(row)
                this.SSWXMID = row.XMID
                this.editStatus = false
                this.showAddModel = true
                this.activeName = '0'
                // this.$refs.detailForm.resetFields();
                this.addStatus = false
               await  this.http.post('/api/Plan_road_land_space/GetPageData',
                   {rows:100,
                       Wheres:JSON.stringify([{Name:'XMMC',Value:row.TXMMC,DisplayType: "Equal"}])
                   }).then(res=>{
                    this.zdInfo = res.rows[0]
                   console.log(this.zdInfo,'zdInfo')
               })
                this.detail = row
               if( this.zdInfo ){

                this.detail.HXMJ = this.zdInfo.HXMJ
                this.detail.NTMJ = this.zdInfo.NTMJ
               }
               if (row.XMID){
                    console.log('绑定了')
                    this.http.post('/api/Plan_high_national_early/queryPlanRoad',{
                        page: 1,
                        rows: 400,
                        Sort: "DSBM",
                        Order: "asc",
                        wheres: JSON.stringify([
                            {
                                Name: "ID",
                                Value: row.XMID,
                                DisplayType: "equal",
                            }
                        ]),
                    }).then(res=> {
                        console.log(res)
                        that.confirmSSW = res.rows
                        that.XMMC = res.rows[0].XMMC
                        console.log(that.confirmSSW)
                        // this.sswxmList = res.rows
                        // this.total1 = res.total
                        // this.$nextTick(()=>{
                        //     if(that.confirmSSW.length != 0){
                        //
                        //         that.$refs.multipleTable.toggleRowSelection(that.sswxmList.find((item) => {
                        //                 if (item.XMMC == that.confirmSSW[0].XMMC){
                        //                     return item
                        //                 }
                        //             }),
                        //             true);
                        //     }
                        // })
                    })
                    // that.sswxmList.find((item) => {
                    //     console.log(item)
                    //     if (item.ID == row.XMID) {
                    //         console.log(item)
                    //         return item
                    //     }
                    // })
                }else{
                    console.log('没有了')
                }
            },
            exportData() {
                var that = this
                this.http.post('/api/Plan_high_national_early/templateWrite',this.postData()).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var timestamp = new Date().getTime();
                    // var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        timestamp+'.xlsx'
                    );
                })
                // exportData("/api/Plan_high_national_early/templateWrite", this.postData());
            },
            getRegion() {
                var postData = { SZDS: "", SZX: "" };
                this.http
                    .post("/api/Base_area/getAdminDivTree", postData)
                    .then((res) => {
                        res.map((r) => {
                            r.value = r.label;
                            r.children.map((rr) => {
                                rr.value = rr.label;
                            });
                        });
                        this.regions = res;

                    });
            },
            //查询
            search() {
                console.log('qingqiuzomg')
                this.$refs.table.bodyWrapper.scrollTop = 0; //滚动条回顶部
                this.http
                    .post(
                        "/api/Plan_road_investment/GetPageData",
                        this.postData(),
                        "正在请求数据，请稍候..."
                    )
                    .then((res) => {
                        this.regions =  [
                            {
                                value: "",
                                label: "黑龙江省",
                            },
                        ]
                        this.getRegion()
                        if (res.status == 0) {
                            this.total = res.total;
                            this.tableData = res.rows;
                        }
                    });
            },
            postData() {
                var szds = "";
                var ssx = "";
                if (this.region.length > 0) {
                    szds = this.region[0];
                    if (this.region.length > 1) {
                        ssx = this.region[1];
                    }
                }
                //所属地市
                var query_ssds = {
                    Name: "DSQ",
                    Value: szds,
                    DisplayType: "like",
                };
                //所属县
                var query_ssx = {
                    Name: "JSZT",
                    Value: ssx,
                    DisplayType: "like",
                };
                //建设类别
                var query_gllx = {
                    Name: "Type",
                    Value: this.gllx,
                    DisplayType: "Equal",
                };
                //项目名称
                var query_xmmc = {
                    Name: "XMMC",
                    Value: this.xmmc,
                    DisplayType: "like",
                };
                var xmxzArray = [];
                this.checkedXmxz.forEach((element) => {
                    xmxzArray.push(element);
                });
                var query_xmxz = {
                    Name: "XMFL",
                    Value: xmxzArray.join(","),
                    DisplayType: "checkbox",
                };
                //风险项目
                var fxxmArray = [];
                this.checkedFxxm.forEach((element) => {
                    fxxmArray.push(element);
                });
                var query_fxxm = {
                    Name: "XMFX",
                    Value: fxxmArray.join(","),
                    DisplayType: "Equal",
                };

                //法人单位
                var query_frdw = {
                    Name: "JSZT",
                    Value: this.frdw,
                    DisplayType: "like",
                };
                //建设规模
                var jsgmArray = [];
                this.checkedJsgm.forEach((element) => {
                    jsgmArray.push(element);
                });
                var query_jsgm = {
                    Name: "JSGMZT",
                    Value: jsgmArray.join(","),
                    DisplayType: "checkbox",
                };

                var postData = {
                    page: this.currentPage,
                    rows: this.pageSize,
                    Sort: "XMXH",
                    Order: "asc",
                    wheres: JSON.stringify([
                        query_gllx,
                        query_ssds,
                        query_ssx,
                        query_frdw,
                        query_xmmc,
                        query_xmxz,
                        query_jsgm,
                        query_fxxm
                    ]),
                };
                return postData;
            },
            postData1(){

                //项目名称
                var query_year = {
                    Name: "Year",
                    Value: this.formInline.year,
                    DisplayType: "Equal",
                };
                var query_Id = {
                    Name: "Plan_Road_Inv_ID",
                    Value: this.oldYearId,
                    DisplayType: "Equal",
                };


                var postData = {
                    page: 1,
                    rows: 20,
                    Sort: "DSBM",
                    Order: "desc",
                    wheres: JSON.stringify([
                        query_year,
                        query_Id
                    ]),
                };
                if(!this.addStatus){
                    postData.value = this.detail.id
                }
                return postData;
            },
            postData2(){

                //项目名称
                var query_xmmc = {
                    Name: "XMMC",
                    Value: this.sswform.XMMC,
                    DisplayType: "like",
                };


                var postData = {
                    page: this.currentPage1,
                    rows: this.pageSize1,
                    Sort: "DSBM",
                    Order: "asc",
                    wheres: JSON.stringify([
                        query_xmmc
                    ]),
                };
                if(!this.addStatus){
                    postData.value = this.detail.id
                }
                return postData;
            },
            clearCondition() {
                this.region = [""];
                this.xmmc = "";
                this.frdw = "";
                this.xmnd = "";
                this.qqgzjz = [];
                this.gllx = "";
                this.checkedXmlb = [];
                this.checkedJsgm = []
                this.checkedGk = [];
                this.checkedCbsj = [];
                this.checkedSgtsj = [];
            },
            handleChange(value) {
                this.checkedXmxz = [];
                this.search();
            },
            handleCheckAllXmxzChange(val) {
                if (val) {
                    if (this.gllx == "高速公路") {
                        this.checkedXmxz = [
                            "“十三五”续建项目",
                            "2021年新开工项目",
                            "“十四五”其他项目",
                            "“十四五”谋划项目",
                        ];
                    } else if (this.gllx == "普通国道") {
                        this.checkedXmxz = [
                            "“十三五”续建项目",
                            "“十四五”新开工项目",
                            "谋划项目",
                            "“十四五”谋划项目",
                        ];
                    } else if (this.gllx == "普通省道") {
                        this.checkedXmxz = [
                            "“十三五”续建项目",
                            "2021年已安排计划项目",
                            "2022年建设项目",
                            "2023年建设项目",
                            "2024-2025年建设项目",
                        ];
                    } else {
                        this.checkedXmxz = this.xmxzList;
                    }
                } else {
                    this.checkedXmxz = [];
                }
                this.isIndeterminateXmxz = false;
            },
            handleCheckAllJsxzChange(val) {
                this.checkedJsxz = val ? this.jsxzList : [];
                this.isIndeterminateJsxz = false;
            },
            //项目类别
            handleCheckAllXmlbChange(val){
                this.checkedXmlb = val ? this.xmlbList : [];
                this.isIndeterminateXmlb = false;
            },
            //建设规模
            handleCheckAllJsgmChange(val){
                this.checkedJsgm = val ? this.jsgmList : [];
                this.isIndeterminateJsgm = false;
            },
            //工可
            handleCheckAllGkChange(val){
                this.checkedGk = val ? this.gkList : [];
                this.isIndeterminateGk = false;
            },
            //初步设计
            handleCheckAllCbsjChange(val){
                this.checkedCbsj = val ? this.cbsjList : [];
                this.isIndeterminateCbsj = false;
            },
            //施工图设计
            handleCheckAllSgtsjChange(val){
                this.checkedSgtsj = val ? this.sgtsjList : [];
                this.isIndeterminateSgtsj = false;
            },
            handleCheckAllXmlxChange(val) {
                if (this.gllx == "普通省道") {
                    this.checkedXmlx = val ? this.xmlxList : [];
                }
                this.isIndeterminateXmlx = false;
            },
            handleCheckedXmxzChange(value) {
                let checkedCount = value.length;
                this.checkAllXmxz = checkedCount === this.xmxzList.length;
                this.isIndeterminateXmxz =
                    checkedCount > 0 && checkedCount < this.xmxzList.length;
            },
            handleCheckedJsxzChange(value) {
                let checkedCount = value.length;
                this.checkAllJsxz = checkedCount === this.jsxzList.length;
                this.isIndeterminateJsxz =
                    checkedCount > 0 && checkedCount < this.jsxzList.length;
            },
            handleCheckedXmlbChange(value){
                let checkedCount = value.length;
                this.checkAllXmlb = checkedCount === this.xmlbList.length;
                this.isIndeterminateXmlb =
                    checkedCount > 0 && checkedCount < this.xmlbList.length;
            },
            handleCheckedJsgmChange(value){
                let checkedCount = value.length;
                this.checkAllJsgm = checkedCount === this.jsgmList.length;
                this.isIndeterminateJsgm =
                    checkedCount > 0 && checkedCount < this.jsgmList.length;
            },
            handleCheckedXmlxChange(value) {
                let checkedCount = value.length;
                this.checkAllXmlx = checkedCount === this.xmlxList.length;
                this.isIndeterminateXmlx =
                    checkedCount > 0 && checkedCount < this.xmlxList.length;
            },
            handleCheckedGkChange(value) {
                let checkedCount = value.length;
                this.checkAllGk = checkedCount === this.gkList.length;
                this.isIndeterminateGk =
                    checkedCount > 0 && checkedCount < this.gkList.length;
            },
            handleCheckedCbsjChange(value) {
                let checkedCount = value.length;
                this.checkAllCbsj = checkedCount === this.cbsjList.length;
                this.isIndeterminateCbsj =
                    checkedCount > 0 && checkedCount < this.cbsjList.length;
            },
            handleCheckedSgtsjChange(value) {
                let checkedCount = value.length;
                this.checkAllSgtsj = checkedCount === this.sgtsjList.length;
                this.isIndeterminateSgtsj =
                    checkedCount > 0 && checkedCount < this.sgtsjList.length;
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.search();
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.search();
            },
            handleSizeChange1(val) {
                this.pageSize1 = val;
                this.getSWWXMList()
            },
            handleCurrentChange1(val) {
                this.currentPage1 = val;
                this.getSWWXMList()

            },
        },
        watch: {
            activeIndex(v) {
                if (v) {
                    this.tableHeight = document.body.offsetHeight - 450;
                } else {
                    this.tableHeight = document.body.offsetHeight - 245;
                }
            },
        },
    };
</script>
<style>
    .hidden {
        display: none ;
    }
    .warning-row {
        background:#fef9f9 !important;
    }
</style>
<style lang="less" scoped>
    .roadFront{
        font-family: "Microsoft YaHei 微软雅黑";
    }
    /deep/.el-step__title.is-process{
        color: #409EFF !important;
        border-color: #409EFF !important;
    }
    /deep/.el-collapse-item__header{
        height: 32px;
        padding:0 15px;
        background: #F2F8FE !important;
    }
    .title{
        padding: 0 16px;
        min-width: 98px;
        height: 32px;
        background: #409EFF;
        opacity: 1;
        line-height: 32px;
        text-align: center;
        color: #fff;
    }
    .file{
        margin-right: 10px;
        display: inline-block;
        margin-top: 5px;
        padding: 0 6px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #B3D8FF;
        opacity: 1;
        border-radius: 4px;
        color: #409EFF;
        a{
            height: 32px;
            line-height: 32px;
        }
    }
    .roadFront{
        /deep/.el-collapse{
            margin-top: 20px;
        }

        /deep/.el-collapse-item__content {
            padding:10px 15px 20px 15px  !important;
        }
        .editModal{
            /deep/.el-input__inner{
                height: 36px;
            }

        }
        /deep/.box-content .el-form-item__label{
            margin-right: 0px;
            text-align: left;
            color: #034761;
            background:unset;
        }
        /deep/ .formBox .el-input--suffix{
            width: 160px;
            margin-right: 10px;
        }
        /deep/.el-form-item__label{
            margin-right: 4px;
            color: #000;
            text-align: center;
            background: #FAFAFA;
        }
        .search{
            /deep/.el-form-item__label{
                background: unset;
            }
        }
    }

    .footer{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 47px;
        background: #F8F8F8;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /*/deep/ .el-input--suffix{*/
    /*    width: 178px;*/
    /*}*/
    .editWrap{
        /deep/.el-drawer__body{
            padding: 12px 15px 0px;
            /*padding-bottom: 60px;*/
        }
        .el-row{
            margin-bottom: 4px;
        }
    }
    /deep/.el-form--inline .el-form-item{
        margin-right: 0;
    }
    /deep/.el-form-item__error--inline {
        position: relative;
        top: auto;
        display: block;
        left: auto;
        margin-left: 10px;
    }
    /deep/.el-step.is-simple:not(:last-of-type) .el-step__title{
        max-width: 70%;
    }
    /*/deep/.el-form-item__error--inline{*/
    /*    top: 14px;*/
    /*    left: 5px;*/
    /*    position: absolute;*/
    /*    background: #fff;*/
    /*    display: inline-block;*/
    /*    margin-left: 10px;*/
    /*}*/
    .bigTitle{
        width: 50%;
        margin: 24px 0;
        /*border-bottom: 1px solid #B3D8FF;*/
        span{
            color: #333;
            font-weight: 600;
            padding: 8px 12px;
            height: 28px;
            background: #B3D8FF;
            opacity: 1;
            border-radius: 0px 14px 0px 0px;
        }

    }
    a{
        border: none;
    }
    .dwmcWrap{
        width: 720px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border:1px solid #DCDFE6;
        border-radius: 4px;
        margin-left: 5px;
        .dwmc{
            text-align: center;
            width: 730px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 36px;
            line-height: 36px;
        }
        .plus{
            border-left: 1px solid #f9f9f9;
            width: 20px;
            line-height: 36px;
            height: 36px;
            i{
                font-size: 16px;
            }
        }
    }
    // 隐藏全选框
    /deep/ .DisableSelection > .cell {
        display: none !important;
    }
    /deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
        color: #fff;
        background: #409eff;
    }
    .fl-jus {
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .label {
        margin-right: 8px;
        width: 126px;
        height: 42px;
        background: #fafafa;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .label2 {
        width: 145px;
        height: 42px;
        background: #fafafa;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .label3 {
        height: 42px;
        background: #fafafa;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .cont {
        width: 220px;
        height: 42px;
        text-align: center;
        line-height: 36px;
    }
.cont2{
    height: 42px;
    text-align: center;
    line-height: 36px;
}
    table {
        border-collapse: collapse;
    }
    td {
        border: 1px solid #dddddd;
        .cont {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .w20{
        width: 220px;
    }

</style>
