<template>
<div class="roadFront" v-if="listData.length!= 0">
<!--    <div class="xmlist">-->
<!--        <div v-for="(item,index) in tableData" :key="index" @click="changeXM(item.ID,index)" class="xmmc" :class="index == activeXm ? 'active' : '' ">-->
<!--            {{item.XMMC}}-->
<!--        </div>-->
<!--    </div>-->
    <el-col >
            <el-table ref="table" :data="listData" size="small "  border :default-sort="{ prop: 'SSDS', order: 'ascending' }" style="width: 97%;margin: 20px auto">
                <el-table-column fixed type="index" align="center"  label="序号" width="50"></el-table-column>
                <el-table-column fixed prop="JDMC" align="center"  label="节点名称"  width="150"></el-table-column>
                <el-table-column  width="200" align="center"  prop="JHWCSJ" label="计划完成时间"></el-table-column>
                <el-table-column width="200" align="center"  prop="PFDW" label="批复单位"></el-table-column>
                <el-table-column width="200" align="center"  prop="PFSJ" label="批复时间" >
                </el-table-column>
                <el-table-column width="200" align="center"  prop="PFWJBT" label="批复文件标题">
                </el-table-column>
            <el-table-column width="200" align="center"  prop="PFWH" label="批复文号">
            </el-table-column>
            <el-table-column width="80" align="center"  prop="BLZT" label="办理状态">
            </el-table-column>
                <el-table-column width="150"  prop="name" label="附件" align="center">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="top" v-if="scope.row.FJ.length > 0">
                            <ul class="fj-list">
                                <li v-for="(item,i) in scope.row.FJ" :key="i">
                                    <a target="view_window" :href="baseUrl+item.url+item.name">
                                        {{item.name}}
                                    </a>
<!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                </li>
                            </ul>

                            <div slot="reference" class="name-wrapper">
                                <el-tag :type="scope.row.FJ.length>0?'success':'danger'" size="small">已上传{{scope.row.FJ.length}}个</el-tag>
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column   prop="GZJZMS" label="工作进展描述"></el-table-column>

            </el-table>
    </el-col>
</div>
</template>

<script>
    export default {
        name: "XmkFlow",
        props:{
            id:{ type: String },
            qqjzid: { type: String },
            GKWCD:  { type: String },
            CBSJWCD:  { type: String },
            SGTSJWCD:  { type: String },
            SGQZBWCD:  { type: String },
            isGg: { type: Boolean },
        },
        data(){
            return{
                activeXm:0,
                tableData:[],
                confirmChange: false,
                baseUrl: "http://82.156.50.94:8002/",
                status:'1',
                showAddModel: false,
                uploadLoading: false,
                detail:{
                    fj:[]
                },
                title:'',
                gg: true,
                percent:'80%',
                scale: 100,
                Collapse: '1',
                jdmc:'',
                lctfl:'',
                qqId:'',
                listData:[],
                result:{
                    GK:{},
                    CBSJ:{},
                    SGTZB:{},
                    SGQZB:{}
                },
                gkwcd:'',
                cbsjwcd:'',
                sgtsjwcd:'',
                shqzbwcd:'',
                dealStatus: '',
                changeDateSwich:true,
                orignDate:'',
                changeTimeReason:'',
                newAdd:true,
            }
        },
        mounted() {

        },
        watch:{
            id:{
                handler(val){
                    console.log(val,'=-----------------------')
                    this.getXm(val)
                },
                immediate: true
            },
            GKWCD(val){
                this.gkwcd = val
            },
            CBSJWCD(val){
                this.cbsjwcd = val
            },
            SGTSJWCD(val){
                this.sgtsjwcd = val
            },
            SGQZBWCD(val){
                this.shqzbwcd = val
            },
            isGg(val){
                this.gg  =val
            },
            qqjzid(val){
                this.qqId = val
                this.getData(val)
            }
        },
        methods:{
            changeXM(id,index){
                this.activeXm = index
                this.getData(id)
            },
            getXm(id){
                this.http
                    .post(
                        "/api/Plan_high_national_early/GetData",
                        {
                            page: 1,
                            rows: 1000,
                            Sort: "DSBM",
                            Order: "asc",
                            wheres: JSON.stringify([
                                { Name: "SSWGHXMID",
                                    Value: id,
                                    DisplayType: "equal"
                                }

                            ])},
                        "正在请求数据，请稍候..."
                    )
                    .then((res) => {
                        if (res.status == 0) {
                            this.total = res.total;
                            this.tableData = res.rows;
                            if(this.tableData.length != 0){
                                this.getData(res.rows[0].ID)
                            }else{
                                this.listData = []
                            }
                        }
                    });
            },
            changeTime(val){
                if (this.detail.ID){
                    this.changeTimeReason = 'little'
                }
                if (this.orignDate){
                    this.changeTimeReason  = this.tab(this.orignDate.slice(0,10),val)
                }
            },
            changeStatus(val){
                this.dealStatus = val
            },
            // 比较大小
            tab(date1,date2){
                var oDate1 = new Date(date1);
                var oDate2 = new Date(date2);
                if(oDate1.getTime() > oDate2.getTime()){
                    return 'little'
                } else if(oDate1.getTime() == oDate2.getTime()) {
                    return 'same'
                }else{
                    return 'big'
                }
            },
            getNowFormatDate() {
                var date = new Date();
                var seperator1 = "-";
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                var strDate = date.getDate();
                if (month >= 1 && month <= 9) {
                    month = "0" + month;
                }
                if (strDate >= 0 && strDate <= 9) {
                    strDate = "0" + strDate;
                }
                var currentdate = year + seperator1 + month  + seperator1 + strDate;
                return currentdate;
            },
            //计算差值

            DateDiff(date1,date2){ //sDate1和sDate2是2006-12-18格式
                let _this = this;
                let flag = [1, 3, 5, 7, 8, 10, 12, 4, 6, 9, 11, 2];
                var cah =  this.tab(date1,date2)
                let start = new Date(date1);
                let end = new Date(date2);
                let year =end.getFullYear() - start.getFullYear();
                var day, month;
                if (cah == 'big'){
                    month =end.getMonth() - start.getMonth();
                    day =end.getDate() - start.getDate();
                    if (month < 0) {
                        year--;
                        month = end.getMonth() + (12 - start.getMonth());
                    }
                    if (day < 0) {
                        month--;
                        let index = flag.findIndex((temp) => {
                            return temp === start.getMonth() + 1
                        });
                        let monthLength;
                        if (index <= 6) {
                            monthLength = 31;
                        } else if (index > 6 && index <= 10) {
                            monthLength = 30;
                        } else {
                            monthLength = 28;
                        }
                        day = end.getDate() + (monthLength - start.getDate());
                    }
                }else{
                    month =start.getMonth() - end.getMonth();
                    day = start.getDate() -end.getDate();
                    if (day < 0) {
                        if (month > 0){
                            month --
                        }
                        let index = flag.findIndex((temp) => {
                            return temp === start.getMonth() + 1
                        });
                        let monthLength;
                        if (index <= 6) {
                            monthLength = 31;
                        } else if (index > 6 && index <= 10) {
                            monthLength = 30;
                        } else {
                            monthLength = 28;
                        }
                        day =start.getDate()+ (monthLength -  end.getDate());
                    }
                }
                var obj = {
                    year: (year == 0?'':  Math.abs(year)+'年') + (month == 0?'':  Math.abs(month)+'月')+ (day == 0?'':  Math.abs(day)+'天'),
                }
                //小于半个月
                if(-15<day<0 && year == 0 && month == 0 ){
                    obj.color='#E6FF00'
                }

                //一个月到半个月之间
                if(year == 0 && month == 0 && (-15>= day >= -30)){
                    obj.color='#00FF77'
                }
                //延期
                if (cah == 'big'){
                    if((day >= 0 && month >= 0 && year >= 0) || (month > 0 && year >= 0) || year > 0){
                        obj.color='#FB0A0A'
                    }
                }


                return obj
                // if (year == 0 && month == 0){
                //     if(day > 0 ){
                //         color = '#FB0A0A'
                //     }else if(0>day>-15){
                //         color = '#E6FF00'
                //     }else if (-15>day>-30 || month == -1){
                //         color = '#00FF77'
                //     }
                //      obj = {
                //         year:`${day}天`,
                //         color:' #00FF77'
                //     }
                //     return obj
                //
                // }else if (year == 0 && month == 0 && day == 0){
                //      obj = {
                //         year:'0天',
                //         color:'#E6FF00'
                //     }
                //     return obj
                // }else if (year == 0){
                //     // return `${month}月${day}天`
                //     if(day > 0 ){
                //         color = '#FB0A0A'
                //     }else if(0>day>-15){
                //         color = '#E6FF00'
                //     }else if (-15>day>-30 || month == -1){
                //         color = '#00FF77'
                //     }
                //     obj = {
                //         year:`${month}月${day}天`,
                //         color:' #00FF77'
                //     }
                //     return obj
                // }else if (month == 0){
                //     // return `${year}年${day}天`
                //     console.log(day,year,'121212121')
                //     if(day > 0 ){
                //         color = '#FB0A0A'
                //     }else if(0>day>-15){
                //         color = '#E6FF00'
                //     }else if (-15>day>-30 && year < 0){
                //         color = '#00FF77'
                //     }
                //     obj = {
                //         year:`${year}年${day}天`,
                //         color:' #00FF77'
                //     }
                //     return obj
                // }else if (day == 0){
                //     if( month == -1){
                //         color = '#00FF77'
                //     }
                //     obj = {
                //         year:`${year}年${month}月`,
                //         color:' #00FF77'
                //     }
                //     return obj
                //     // return `${year}年${month}月`
                // }else if (day == 0 && month == 0){
                //     obj = {
                //         year:`${year}年`,
                //         color:'#ffffff'
                //     }
                //     return obj
                //     // return `${year}年`
                // }else{
                //     // return '<span style="color: #00FF77">{{${year}年${month}月${day}天}}</span>'
                // }

                //
            },

            deleted(index,type){
                this.detail.FJ.splice(index,1)
            },
            getData(id){
                this.result = {GK:{}, CBSJ:{}, SGTZB:{}, SGQZB:{}}
                this.http.post('/api/Plan_high_national_early_node/GetPageData',{Wheres:JSON.stringify([{Name:'QQJZID',Value:id,DisplayType: "Equal"}])}).then(res=>{
                    this.listData = res.rows
                    this.listData.map((item,index)=>{
                        var a = []
                        if(item.FJ != ''){
                            item.FJ = item.FJ.split(';')
                            item.FJ.map((subItem)=>{
                                subItem = {url:subItem.substring(0,subItem.lastIndexOf("/") + 1),name:subItem.substring(subItem.lastIndexOf("/") + 1,subItem.length)}
                                a.push(subItem)
                            })
                            item.FJ = a
                        }else {
                            item.FJ = []
                        }

                    })
                    this.listData.map((item,index)=>{
                        switch (item.LCTFL) {
                            case 'GK':
                                this.result.GK[item.JDMC] = item
                                break;
                            case 'CBSJ':
                                this.result.CBSJ[item.JDMC] = item
                                break;
                            case 'SGTZB':
                                this.result.SGTZB[item.JDMC] = item
                                break;
                            case 'SGQZB':
                                this.result.SGQZB[item.JDMC] = item
                                break;
                        }
                    })
                    this.detail = {FJ:[]}
                })
            },
            onSubmit(e, type) {
                this.uploadLoading = true
                let file = e.target.files;
                let form = new FormData();
                let arrs = [];
                file.forEach((element) => {
                    form.append("fileInput", element);
                    arrs.push(element.name);
                });
                this.http.post("/api/Plan_high_national_early_node/upload", form)
                    .then((res) => {
                        this.uploadLoading = false
                        arrs.forEach((v) => {
                            // this.details[type].push(res.data + v);
                            this.detail.FJ.push({url:res.data, name:v})

                        });
                        // this.detail[type].pfwj.push({url:res.data, name:file[0].name})
                    });

            },
            save(){
                this.$refs.detailForm.validate((valid) => {
                    if (valid) {
                        var params = JSON.parse(JSON.stringify(this.detail))
                        var pfwjString = []
                        params.FJ.map((item)=>{
                            pfwjString.push(item.url+item.name)
                        })
                        params.FJ = pfwjString.join(';')
                        params.QQJZID = this.qqId
                        params.JDMC = this.jdmc
                        params.LCTFL = this.lctfl
                        if(!params.ID){
                            params.ID = '00000000-0000-0000-0000-000000000000'
                            this.http.post('/api/Plan_high_national_early_node/AddPlanHighNationalEarlyNode',params).then(res=>{
                                if(res.status){
                                    this.dealStatus = ''
                                    this.$message.success('添加成功')
                                    this.showAddModel = false
                                    this.detail ={FJ:[]}
                                    this.getData(this.qqId)
                                    this.$parent.$parent.closeInnerDrawer();
                                }
                            })

                        }else{
                            this.http.post('/api/Plan_high_national_early_node/UpdatePlanHighNationalEarlyNode',params).then(res=>{
                                if(res.status){
                                    this.$message.success('保存成功')
                                    this.showAddModel = false
                                    this.dealStatus = ''
                                    this.detail ={FJ:[]}
                                    this.getData(this.qqId)
                                    this.$parent.$parent.closeInnerDrawer();
                                }
                            })
                        }
                    }
                })

            },
            checkNull(rule, value, callback){
                if (!value) {
                    callback(new Error('请选择时间'));
                }else{
                    callback();
                }
            },
            cancel(){},
            openEdit(name,jdmc,lctfl){
                var that = this
                this.changeTimeReason = ''
                this.detail = {FJ:[]}
                this.title = name
                this.jdmc = jdmc
                this.lctfl =  lctfl
                if ( this.result[lctfl][jdmc] ){
                    this.detail =  JSON.parse(JSON.stringify(this.result[lctfl][jdmc]))
                    this.dealStatus =  this.detail.BLZT
                    this.orignDate = this.detail.JHWCSJ
                    this.newAdd = true
                    // this.changeDateSwich = this.detail.JHWCSJ ? true
                }else{
                    this.newAdd = false

                }
            },
            changeCollapse(){

            },
        }
    }
</script>

<style lang="less" scoped>
    .roadFront{
        font-family: "Microsoft YaHei 微软雅黑";
    }
    /deep/.el-step__title.is-process{
        color: #409EFF !important;
        border-color: #409EFF !important;
    }
    /deep/.el-collapse-item__header{
        height: 32px;
        padding:0 15px;
        background: #F2F8FE !important;
    }
    .title{
        padding: 0 16px;
        min-width: 98px;
        height: 32px;
        background: #409EFF;
        opacity: 1;
        line-height: 32px;
        text-align: center;
        color: #fff;
    }
    .file{
        margin-right: 10px;
        display: inline-block;
        margin-top: 5px;
        padding: 0 6px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #B3D8FF;
        opacity: 1;
        border-radius: 4px;
        color: #409EFF;
        a{
            height: 32px;
            line-height: 32px;
        }
    }
    .roadFront{
        /deep/.el-collapse{
            margin-top: 20px;
        }
        /deep/.el-form--inline .el-form-item{
            margin:0;
        }
        /deep/.el-collapse-item__content {
            padding:10px 15px 20px 15px  !important;
        }
        /deep/.el-input__inner{
            height: 32px;
            line-height: 32px;
            width: 178px;
        }
        .editModal{
            /deep/.el-input__inner{
                height: 32px;
                line-height: 32px;
                width: 178px;
            }

        }
        /deep/.box-content .el-form-item__label{
            margin-right: 0px;
            text-align: left;
            color: #034761;
            background:unset;
        }
        /deep/.el-form-item__label{
            margin-right: 4px;
            color: #000;
            text-align: center;
            background: #FAFAFA;
        }
        .search{
            /deep/.el-form-item__label{
                background: unset;
            }
        }
    }

    .footer{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 47px;
        background: #F8F8F8;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /deep/ .el-input--suffix{
        width: 178px;
    }
    .editWrap{
        /deep/.el-drawer__body{
            padding: 12px 15px 0px;
            /*padding-bottom: 60px;*/
        }
        .el-row{
            margin-bottom: 4px;
        }
    }

    /deep/.el-form-item__error--inline {
        position: relative;
        top: auto;
        display: block;
        left: auto;
        margin-left: 10px;
    }
    /deep/.el-step.is-simple:not(:last-of-type) .el-step__title{
        max-width: 70%;
    }
    /*/deep/.el-form-item__error--inline{*/
    /*    top: 14px;*/
    /*    left: 5px;*/
    /*    position: absolute;*/
    /*    background: #fff;*/
    /*    display: inline-block;*/
    /*    margin-left: 10px;*/
    /*}*/
    .bigTitle{
        width: 50%;
        margin: 24px 0;
        /*border-bottom: 1px solid #B3D8FF;*/
        span{
            color: #333;
            font-weight: 600;
            padding: 8px 12px;
            height: 28px;
            background: #B3D8FF;
            opacity: 1;
            border-radius: 0px 14px 0px 0px;
        }

    }
    a{
        border: none;
    }
    .dwmcWrap{
        width: 350px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 15px;
        border:1px solid #DCDFE6;
        border-radius: 2px;
        .dwmc{
            text-align: center;
            width: 330px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 40px;
            background: #fafafa;
        }
        .plus{
            border-left: 1px solid #f9f9f9;
            width: 20px;
            height: 40px;
            background: #fafafa;
            i{
                font-size: 16px;
            }
        }
    }
    /deep/.el-textarea{
        display: unset;
    }
    // 隐藏全选框
    /deep/ .DisableSelection > .cell {
        display: none !important;
    }
    /deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
        color: #fff;
        background: #409eff;
    }
    .xmlist{
        margin: 20px 0 0 20px;
        display: flex;
        align-items: center;
        .xmmc{
            cursor: pointer;
            background: #e6effc;
            color: #666;
            padding: 5px 10px;
            margin-right: 5px;
        }
        .active{
            background: #4c98da;
            color: #ffffff;
        }
    }
</style>
